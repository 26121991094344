<template>
  <div class="banner">
    <div class="banner__title">
      <span v-html="text"></span>
      <h1>
        <p>100% <span>bonus</span> up to</p>
        <p><span>£{{ bonus }}</span> + <span>100 Spins</span></p>
      </h1>
    </div>
    <Button
        title="Play Here"
        :target="true"
    />
    <p>
      18+. New Players Only. Min. deposit £20. Max bonus allowed is £50. Offer
      duration upon receiving is valid for 2 weeks (14 days). Max Win from spins
      is £100. 35x wagering applies both for bonus and spins part. Spins are
      credited on specific games(Starburst). Spins expire after 24 hours if
      unused. Full T&Cs Apply. Live games do not contribute towards wager
      requirements.
    </p>
    <div class="banner__icons">
      <div>
        <img src="@/assets/images/banner/be-gamble-aware.svg" alt="BeGambleAware.org">
        <img src="@/assets/images/banner/18+.svg" alt="18+">
      </div>
      <p>Gambling can be addictive, please play responsibly.</p>
    </div>
  </div>
</template>

<script>
import Button from "./layout/Button.vue";
import {hostName} from "@/assets/helpers/hostName";

export default {
  name: "Banner",
  components: {Button},
  computed: {
    isHostName() {
      return hostName() === 'welcome.heyspin';
    },
    text() {
      return this.isHostName ? 'Exclusive Welcome Offer <br> for New Players' : 'Exclusive  <br> Welcome Offer';
    },
    bonus() {
      return this.isHostName ? '25' : '50'
    }
  },
};
</script>

<style lang="scss">
.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 154px 0 34px;
  text-align: center;
  background-image: url("@/assets/images/banner/bg-mobile.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @media (min-resolution: 2dppx) {
    background-image: url("@/assets/images/banner/bg-mobile@2x.jpg");
  }

  @media (min-width: $size-lg) {
    padding: 115px 0 110px;
    background-image: url("@/assets/images/banner/bg.jpg");

    @media (min-resolution: 2dppx) {
      background-image: url("@/assets/images/banner/bg@2x.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 1.35;

      @media (min-width: $size-lg) {
        margin-bottom: 26px;
        font-size: 34px;
      }
    }

    > h1 {
      margin-bottom: 26px;
      font-family: 'Roboto Slab', sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.1;
      text-transform: uppercase;

      @media (min-width: $size-lg) {
        font-size: 40px;
      }

      > p {
        &:last-child {
          font-size: 29px;
          line-height: 1.3;

          @media (min-width: $size-lg) {
            font-size: 58px;
          }
        }
      }

      span {
        color: var(--color-yellow);
      }
    }
  }

  .link {
    margin-bottom: 26px;
  }

  > p {
    max-width: 428px;
    padding: 0 15px;
    font-size: 10px;
    color: var(--color-grey-light);

    @media (min-width: $size-lg) {
      font-size: 12px;
    }
  }

  &__icons {
    position: absolute;
    top: 11px;
    right: 100%;
    width: 100%;
    transform: translate(105%, 0);

    @media (min-width: $size-lg) {
      top: 84%;
      right: 2%;
      width: auto;
      transform: translate(0,0);
    }

    @media (min-width: $size-xl) {
      top: 89%;
    }

    > div {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2px;

      > img {
        width: 23px;

        @media (min-width: $size-lg) {
          width: auto;
        }

        &:not(:last-child) {
          width: 164px;
          margin-right: 5px;

          @media (min-width: $size-lg) {
            width: auto;
            margin-right: 8px;
          }
        }
      }
    }

    > p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 7px;
      line-height: 1.4;
      color: var(--color-white);
      text-align: left;

      @media (min-width: $size-lg) {
        font-size: 12px;
      }
    }
  }
}
</style>
