<template>
  <div class="game-card">
    <picture>
      <source
          type="image/webp"
          :srcset="
          require(`@/assets/images/gameCards/webp/${card.src}.webp`) +
          ' 1x, ' +
          require(`@/assets/images/gameCards/webp/${card.src}@2x.webp`) +
          '2x'
        "
          alt="card-image"
          class="game-card__image"
      />
      <img
          :src="require(`@/assets/images/gameCards/png/${card.src}.png`)"
          :srcset="
          require(`@/assets/images/gameCards/png/${card.src}.png`) +
          ' 1x, ' +
          require(`@/assets/images/gameCards/png/${card.src}@2x.png`) +
          '2x'
        "
          alt="card-image"
          class="game-card__image"
      />
    </picture>
    <h3 class="game-card__title" v-html="card.title"></h3>
    <p class="game-card__desc">{{ card.desc }}</p>
  </div>
</template>

<script>
export default {
  name: "GameCard",
  props: {
    card: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.game-card {
  position: relative;
  z-index: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  max-width: 288px;
  height: 382px;
  padding: 44px 25px 40px;
  border-radius: 6px;
  text-align: center;
  border: 1px solid var(--color-grey-dark);

  @media (min-width: $size-lg) {
    max-width: 390px;
    height: 530px;
    padding: 52px 35px 70px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 6px;
  }

  &:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--color-black);
  }

  &:after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    z-index: -2;
    background: var(--color-outlines-inner-border);
  }

  &__image {
    width: 238px;

    @media (min-width: $size-lg) {
      width: 100%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: var(--color-white);
    margin-bottom: 14px;

    @media (min-width: $size-lg) {
      font-size: 24px;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: var(--color-grey-light);

    @media (min-width: $size-lg) {
      font-size: 16px;
    }
  }
}
</style>
