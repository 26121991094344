<template>
  <div class="payment">
    <div class="container">
      <div class="payment__list">
        <img
          class="payment__image"
          v-for="(path, index) in paths"
          :key="`payment-${index}`"
          :src="require(`@/assets/images/payment/${path}.svg`)"
          alt="image"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",
  data() {
    return {
      paths: [
        "visa",
        "mastercard",
        "paypal",
        "trustly",
        "muchbetter",
        "skrill",
      ],
    };
  },
};
</script>

<style lang="scss">
.payment {
  border: solid var(--color-grey-dark);
  border-width: 1px 0 1px 0;

  &__list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px 0;

    @media (max-width: $size-md) {
      padding: 16px 0;
    }
  }

  &__image {
    width: 136px;

    @media (max-width: $size-xl) {
      width: 125px;
    }

    @media (max-width: $size-md) {
      width: 96px;
    }
  }
}
</style>
