<template>
  <div class="container">
    <div class="game-cards">
      <h2>
        Explore <span>HeySpin's Live</span> Games
      </h2>
      <p>
        Including Blackjack, Roulette, Baccarat, Live Poker & So Much More!
      </p>
      <div class="game-cards__list">
        <GameCardsItem v-for="card in cards" :card="card" :key="card.key" />
      </div>
      <Button
          title="Play Here"
          :target="true"
      />
    </div>
  </div>
</template>

<script>
import GameCardsItem from "./GameCardsItem.vue";
import Button from "../layout/Button.vue";

export default {
  name: "GameCards",
  components: { GameCardsItem, Button },
  data() {
    return {
      cards: [
        {
          id: 1,
          title: "Roulette games",
          desc: "Can you guess where the Live Roulette ball will stop? Choose your numbers. Place your bets.",
          src: "card1",
        },
        {
          id: 2,
          title: "Baccarat Games",
          desc: "Face the dealer as you wait to see who comes out on top. Bet on the dealer, player, or a tie to win.",
          src: "card2",
        },
        {
          id: 3,
          title: "Blackjack",
          desc: "Take part in perhaps the most fun betting events of all. Be entertained by the vibrant game shows and place your bets to win.",
          src: "card3",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.game-cards {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 26px 0 40px;
  text-align: center;

  @media (min-width: $size-lg) {
    padding: 90px 0;
    text-align: left;
  }

  > h2 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--color-white);

    @media (min-width: $size-lg) {
      margin-bottom: 10px;
      font-size: 46px;
    }

    > span {
      color: var(--color-yellow);
    }
  }

  > p {
    font-size: 12px;
    color: var(--color-grey-light);
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 29px 0 16px;

    @media (min-width: $size-lg) {
      flex-direction: row;
      padding: 80px 0 40px;
    }

    .game-card {
      margin: 0 0 16px;

      @media (min-width: $size-lg) {
        margin: 0 10px 10px;
      }

      @media (min-width: $size-xl) {
        margin: 0 10px;
      }
    }
  }
}
</style>
