<template>
  <div id="app">
    <Header />
    <Banner />
    <Payment />
    <GameCards />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Payment from "@/components/Payment.vue";
import GameCards from "@/components/GameCards/GameCards.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: { Header, Banner, Payment, GameCards, Footer },
};
</script>
