<template>
    <header class="header">
      <picture>
        <source
          type="image/webp"
          srcset="
            @/assets/images/header/webp/logo.webp 1x,
            @/assets/images/header/webp/logo@2x.webp 2x
          "
          alt="logo"
          class="header__logo"
        />
        <img
          src="@/assets/images/header/png/logo.png"
          srcset="
            @/assets/images/header/png/logo.png 1x,
            @/assets/images/header/png/logo@2x.png 2x
          "
          alt="logo"
          class="header__logo"
        />
      </picture>
    </header>
  </template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss">
.header {
  text-align: center;
  border-bottom: 1px solid var(--color-grey-dark);
  height: 93px;

  @media (max-width: $size-xl) {
    height: 74px;
  }

  @media (max-width: $size-lg) {
    height: 67px;
  }

  @media (max-width: $size-md) {
    height: 49px;
  }

  &__logo {
    height: 100%;
  }
}
</style>
