<template>
  <div class="container footer">
    <p
        v-for="(text, i) in texts"
        :key="i"
        v-html="(text)"
    >
      <slot></slot>
    </p>
  </div>
</template>

<script>
import {hostName} from "@/assets/helpers/hostName";

export default {
  name: "Footer",
  computed: {
    texts() {
      return [
        "New depositing players only. <br> Min. deposit £20.",
        `Welcome package includes a deposit bonus as follows: First Deposit: 100% up to £${this.bonus} + 100 Spins on Starburst *Min. deposit £20 S. Winnings won with spins that require deposit, have to be wagered 35x. Bonuses that require deposit, have to be wagered 35x. This offer is available to players residing in United Kingdom only.`,
        'Deposits may be withdrawn before a player’s wagering requirements have been fulfilled. However, if this occurs, all bonuses and winnings will be voided/removed from the player’s account. Players must contact support in order to withdraw their deposit. <br> Bets on different games contribute differently to the compliance of the wagering requirements: <a href="https://heyspin.com/?NeoDL=Registration" target="_blank">Wagering, Bonus Policy.</a>',
        'Winnings received through the use of the deposit Spins shall not exceed £100. Any winnings which shall be made with the deposit Spins which exceeds the £100 maximum, shall be considered as nil, and shall not be recorded as winnings. Only the maximum winnings of £100 can be withdrawn by the Player subject to the terms specified in the <a href="https://heyspin.com/?NeoDL=Registration" target="_blank">Bonus Policy.</a>',
        'When a bet abuses the betting system – regardless of which game it was placed on – it will not count towards a player’s wagering requirements. If the player placed single bets equal to or greater than £4 or £0.50 per line, or to withhold any amount in excess of the player’s original deposit from a player’s withdrawal. In addition, if a player deposited with Skrill or Neteller and placed single bets equal to or greater than £2.50 of the bonus amount before the wagering requirements for the bonus have been met, we reserve the right to withhold any amount in excess of the player’s original deposit from a player’s withdrawal. If the player placed bets on any of the games which contribute 0% to the wagering requirement before the wagering requirements for the bonus have been met, we reserve the right to withhold any amount in excess of a player’s original deposit from a player’s withdrawal.',
        'In Great Britain only, the games on this website are operated by AG Communications Limited, a Malta based company with registration number C48328 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is licensed and regulated by the United Kingdom Gambling Commission (Under account number: 39483). The current status of operators license can be found at: <a href="https://heyspin.com/?NeoDL=Registration" target="_blank">Gambling Commission.</a>',
        '<a href="https://heyspin.com/?NeoDL=Registration" target="_blank">Bonus Policy</a> and <a href="https://heyspin.com/?NeoDL=Registration" target="_blank">Terms of Service</a> apply.',
      ]
    },
    bonus() {
      return hostName() === 'welcome.heyspin' ? '25' : '50'
    }
  },
};
</script>

<style lang="scss">
.footer {
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  color: var(--color-grey-light);

  @media (min-width: $size-lg) {
    font-size: 14px;
  }

  &.container {
    padding: 0 15px 40px;

    @media (min-width: $size-lg) {
      padding: 0 15px 60px;
    }
  }

  > p {
    margin-top: 1.1em;
    margin-bottom: 1.1em;
  }

  a {
    color: var(--color-yellow);
    transition: color .3s ease-in-out;

    &:active {
      color: var(--color-yellow-hover);
    }

    @media (min-width: $size-lg) {
      &:hover {
        color: var(--color-yellow-hover);
      }
    }
  }
}
</style>
